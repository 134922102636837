import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { dashboardStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import ShowPopup from '@/components/ShowPopup.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import CardSemana from './CardSemana.vue';
let QuadroPedagogico = class QuadroPedagogico extends Vue {
    constructor() {
        super(...arguments);
        this.headerMonth = [
            {
                text: 'Total Alunos',
                value: 'total_alunos_mes',
            },
            {
                text: 'Presença',
                value: 'presenca_mes',
            },
            {
                text: 'Falta',
                value: 'falta_mes',
            },
            {
                text: '% Falta',
                value: 'percentual_mes',
            },
            {
                text: 'Nº de turmas ',
                value: 'numero_turmas',
            },
            {
                text: 'Evadidos',
                value: 'evadidos',
            },
            {
                text: 'Desistentes',
                value: 'desistente',
            },
            {
                text: 'Virada de livro',
                value: 'virada_livros',
            },
            {
                text: 'Rematrículas',
                value: 'rematriculas',
            },
        ];
        this.headers = [
            {
                text: 'Professor',
                sortable: true,
                value: 'full_name',
            },
            {
                text: 'Total Alunos',
                value: 'total_alunos_1',
            },
            {
                text: 'Presença',
                value: 'presenca_semana_1',
            },
            {
                text: 'Falta',
                value: 'falta_semana_1',
            },
            {
                text: '% Falta',
                value: 'percentual_semana_1',
            },
            {
                text: 'Total Alunos',
                value: 'total_alunos_2',
            },
            {
                text: 'Presença',
                value: 'presenca_semana_2',
            },
            {
                text: 'Falta',
                value: 'falta_semana_2',
            },
            {
                text: '% Falta',
                value: 'percentual_semana_2',
            },
            {
                text: 'Total Alunos',
                value: 'total_alunos_3',
            },
            {
                text: 'Presença',
                value: 'presenca_semana_3',
            },
            {
                text: 'Falta',
                value: 'falta_semana_3',
            },
            {
                text: '% Falta',
                value: 'percentual_semana_3',
            },
            {
                text: 'Total Alunos',
                value: 'total_alunos_4',
            },
            {
                text: 'Presença',
                value: 'presenca_semana_4',
            },
            {
                text: 'Falta',
                value: 'falta_semana_4',
            },
            {
                text: '% Falta',
                value: 'percentual_semana_4',
            },
            {
                text: 'Total Alunos',
                value: 'total_alunos_5',
            },
            {
                text: 'Presença',
                value: 'presenca_semana_5',
            },
            {
                text: 'Falta',
                value: 'falta_semana_5',
            },
            {
                text: '% Falta',
                value: 'percentual_semana_5',
            },
            ...this.headerMonth,
        ];
        this.detailHeaders = [];
        this.detailHeaders1234 = [
            {
                text: 'Qtd',
                sortable: true,
                value: 'qtd',
            },
            {
                text: 'Aluno',
                sortable: true,
                value: 'aluno_nome',
            },
            {
                text: 'Turma',
                sortable: true,
                value: 'turma_codigo_referencia',
            },
            {
                text: 'Livro',
                sortable: true,
                value: 'livro_nome',
            },
            {
                text: 'Atividade',
                sortable: true,
                value: 'atividade_descricao',
            },
            {
                text: 'Data',
                sortable: true,
                value: 'atividade_data_executada',
            },
        ];
        this.detailHeaders5 = [
            {
                text: 'Qtd',
                sortable: true,
                value: 'qtd',
            },
            {
                text: 'Turma',
                sortable: true,
                value: 'turma_codigo_referencia',
            },
            {
                text: 'Livro',
                sortable: true,
                value: 'livro_nome',
            },
            {
                text: 'Previsão de inicio',
                sortable: true,
                value: 'previsao_inicio',
            },
            {
                text: 'Previsão de termino',
                sortable: true,
                value: 'previsao_termino',
            },
        ];
        this.detailHeaders67 = [
            {
                text: 'Qtd',
                sortable: true,
                value: 'qtd',
            },
            {
                text: 'Aluno',
                sortable: true,
                value: 'aluno_nome',
            },
            {
                text: 'Turma',
                sortable: true,
                value: 'turma_codigo_referencia',
            },
            {
                text: 'Livro',
                sortable: true,
                value: 'livro_nome',
            },
            {
                text: 'Data da operação',
                sortable: true,
                value: 'data',
            },
            {
                text: 'Motivo',
                sortable: true,
                value: 'motivo',
            },
        ];
        this.detailHeaders68 = [
            {
                text: 'Qtd',
                sortable: true,
                value: 'qtd',
            },
            {
                text: 'Aluno',
                sortable: true,
                value: 'aluno_nome',
            },
            {
                text: 'Turma',
                sortable: true,
                value: 'turma_codigo_referencia',
            },
        ];
        this.search = '';
        this.loading = false;
        this.selectedMonth = null;
        this.items = [];
        this.ano = null;
        this.detailTitle = '';
        this.detailShow = false;
        this.detailItems = [];
        this.eachTable = false;
        this.entireTable = true;
        this.weeks = [];
        this.updated_at = '';
    }
    numberWeek(item, name) {
        // Criar um Array com os valores das semanas.
        const weekValues = Array.from({ length: 5 }, (_, index) => {
            const dataKey = `${name}${index + 1}`;
            return item[dataKey];
        });
        const total = weekValues.reduce((acc, value) => acc + value, 0);
        return total;
    }
    calculatePercentual(item, weekNumber) {
        const presencaKey = `presenca_semana_${weekNumber}`;
        const faltaKey = `falta_semana_${weekNumber}`;
        const presenca = item[presencaKey];
        const falta = item[faltaKey];
        const totalAulas = presenca + falta;
        const percentual = (falta / totalAulas) * 100;
        return percentual;
    }
    getCurrentUpdate(horario) {
        if (horario) {
            // @ts-ignore
            return this.formatDateTime(new Date(new Date(horario).getTime()));
        }
        // @ts-ignore
        return this.formatDateTime(new Date());
    }
    async buscar() {
        this.loading = true;
        const data = await dashboardStore.getDashboardQuadroPedagogico({
            ano: this.ano,
            mes: this.selectedMonth,
        });
        this.items = data.items;
        this.weeks = data.weeks;
        this.updated_at = data.updated_at;
        this.loading = false;
    }
    async showCardSemana(item, semana, tipoDado) {
        const orderedWeeks = this.weeks.reverse();
        const data_inicio = orderedWeeks[semana - 1][0];
        const data_fim = orderedWeeks[semana - 1][1];
        this.show(item, data_inicio, data_fim, tipoDado);
    }
    async show(item, data_inicio, data_fim, tipoDado) {
        if (tipoDado == 1) {
            this.detailTitle = `Presença ${data_inicio} - ${data_fim}`;
            this.detailHeaders = this.detailHeaders1234;
        }
        else if (tipoDado == 2) {
            this.detailTitle = `Falta ${data_inicio} - ${data_fim}`;
            this.detailHeaders = this.detailHeaders1234;
        }
        else if (tipoDado == 3) {
            this.detailTitle = 'Presença no mês';
            this.detailHeaders = this.detailHeaders1234;
        }
        else if (tipoDado == 4) {
            this.detailTitle = 'Falta no mês';
            this.detailHeaders = this.detailHeaders1234;
        }
        else if (tipoDado == 5) {
            this.detailTitle = 'Número de turmas';
            this.detailHeaders = this.detailHeaders5;
        }
        else if (tipoDado == 6) {
            this.detailTitle = 'Evadidos no mês';
            this.detailHeaders = this.detailHeaders67;
        }
        else if (tipoDado == 7) {
            this.detailTitle = 'Desistentes no mês';
            this.detailHeaders = this.detailHeaders67;
        }
        else if (tipoDado === 8) {
            this.detailTitle = `Total de alunos ${data_inicio} - ${data_fim}`;
            this.detailHeaders = this.detailHeaders68;
        }
        this.detailItems = await dashboardStore.getDashboardQuadroPedagogicoDetails({
            data_inicio,
            data_fim,
            professor_id: item.professor_id,
            tipo_dado: tipoDado,
        });
        this.detailShow = true;
        // @ts-ignore
        const form = this.$refs.detailShow;
        form.show();
    }
    async mounted() {
        const date = new Date();
        this.selectedMonth = date.getMonth() + 1;
        this.ano = date.getFullYear();
        // @ts-ignore
        this.eachTable = this.isMobile;
        // @ts-ignore
        this.entireTable = !this.isMobile;
    }
};
QuadroPedagogico = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            ShowPopup,
            OpenNewTabComponent,
            TextExpandableComponent,
            CardSemana,
        },
    })
], QuadroPedagogico);
export default QuadroPedagogico;
